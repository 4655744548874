<template>
  <div>
    <h2>Http Raw 转 Python代码</h2>
    <a-row>
      <a-col :span="11">
        <a-textarea placeholder="请将Http Raw值粘贴进来" style="min-height:500px;" v-model="rawData" />
      </a-col>

      <a-col :span="2" style="text-align:center">
        <a-button v-on:click="transClick">转换</a-button>
      </a-col>

      <a-col :span="11">
        <ToolRightWrapper :transData="transData" />
      </a-col>
    </a-row>

    <div class="content">
      <h2>使用方法</h2>
      <a-row>
        <ol class="use-direction">
          <a-col :span="8">
            <li>复制抓包工具里的Raw内容，支持 Fiddler 或 Charles</li>

            <div>
              <p style="margin:0.1rem;font-size:15px">
                <b>Fiddler:</b>
              </p>
              <img src="https://images.alisen39.com/20200525221208.png" alt />

              <p style="margin:0;font-size:15px">
                <b>Charles:</b>
              </p>
              <img src="https://images.alisen39.com/20200525214527.png" alt />
            </div>
          </a-col>

          <a-col :span="15" :offset="1">
            <li>将其粘贴至本页面左边窗口 ，点击"转换"按钮即可得到结果</li>
            <img src="https://images.alisen39.com/20200525224438.png" alt />
          </a-col>
        </ol>
      </a-row>
    </div>
  </div>
</template>
<script>
const axios = require('axios')
import ToolRightWrapper from '../components/ToolRightWrapper.vue'

export default {
  name: 'HttpRaw',
  components: {
    ToolRightWrapper
  },
  metaInfo: {
    title: 'Http raw转requests代码 | 麦麦麦造的小站', // set a title
    meta: [
      {
        // set meta
        name: 'keyWords',
        content:
          'Fiddler,Fiddler data,Fiddler Cookie,数据采集工具,爬虫开发工具,Http raw,快速爬虫开发'
      },
      {
        name: 'description',
        content: '将Http raw格式的信息快速转换为Python3下的Requests的代码'
      }
    ]
  },
  data() {
    return {
      rawData: '',
//             transData: `import requests

// url = 'https://www.telerik.com/account/api/v2/environments/current'
// header = {'Host': 'localhost.charlesproxy.com:8000', 'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:74.0) Gecko/20100101 Firefox/74.0', 'Accept': 'application/json, text/plain, */*', 'Accept-Language': 'zh-CN,zh;q=0.8,zh-TW;q=0.7,zh-HK;q=0.5,en-US;q=0.3,en;q=0.2', 'Accept-Encoding': 'gzip, deflate', 'Content-Type': 'application/x-www-form-urlencoded', 'Content-Length': '61', 'Origin': 'http://localhost.charlesproxy.com:8000', 'Referer': 'http://localhost.charlesproxy.com:8000/tools/fiddler_data/', 'DNT': '1', 'Connection': 'keep-alive'}

// res = requests.request(method = 'POST' ,url = url ,headers = header ,verify = False,data={'data': '', 'other': 'asdf', 'other2': '中国文学'})
// res_data = res.content.decode('utf8')
// print(res_data)`
      transData: ''
    }
  },
  methods: {
    transClick() {
      const _this = this
      if (this.$data.rawData.replace(' ', '').length <= 0) {
        this.$message.error('还没有输入参数哦！')
        return
      }

      axios({
        url: '/tools/http_raw/',
        method: 'post',
        data: {
          data: this.$data.rawData
        }
      })
        .then(function(response) {
          if (response.data['code'] != 200) {
            _this.$message.warning(response.data['msg'])
            return
          }
          _this.$data.transData = response.data['data']
        })
        .catch(function(error) {
          _this.$message.error(error.message)

          // console.log(error)
        })
    }
  },
  mounted: function() {}
}
</script>

<style>
@import url('./tools.css');

/* textarea {
  resize: none;
}
.content {
  margin-top: 1rem;
}
.use-direction li {
  font-size: 15px;
  margin-bottom: 0.5rem;
}
.use-direction img {
  max-width: 100%;
  margin: 0 auto 3% auto;
} */
</style>